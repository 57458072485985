'use client';

import Link from 'next/link'
import Image from "lib/image";
import { HiOutlinePlusCircle } from 'react-icons/hi';
import { useFetch } from 'lib/useInfiniteFetch';

export function SinglePack({ pack, slider = true, apiType }) {

    return (
        <Link
            key={pack.uname}
            href={"/host/property/[uname]"}
            as={"/host/property/" + pack.uname}
            legacyBehavior>
            <div className={`w-[65vw] lg:w-full`}>
                <div className='relative h-[11rem] lg:h-80 w-full rounded-xl overflow-hidden bg-gray-100'>
                    {pack?.img ? <Image
                        className="object-cover"
                        layout="fill"
                        placeholder="blur"
                        blurDataURL={pack?.img?.blurData}
                        src={'uploads/' + pack?.img?.src}
                    /> : null}
                    <div className="bg-gradient-to-t from-gray-800 absolute h-32 w-full bottom-0"></div>
                    <div className="absolute bottom-0 left-0 w-full py-2 px-2 z-10">
                        {pack?.meta?.cashfree_beneficiary_id ? null : <div className="flex items-center space-x-1 mb-1">
                            <div class="relative rounded-full h-3 w-3 bg-yellow-500">
                                <div className='flex items-center justify-center absolute w-full h-full'>
                                    <span class="animate-ping inline-flex h-2 w-2 rounded-full bg-yellow-400 opacity-75"></span>
                                </div>
                            </div>
                            <p className='text-white text-xs'>Bank Details Missing</p>
                        </div>}
                        <h2 className="text-white text-sm font-medium truncate">{pack.name}</h2>
                        <div className='font-extralight flex items-center justify-between w-full text-sm text-white'>
                            <p className="truncate">{pack.city?.name || pack.city}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

const MyProperties = () => {

    const { data } = useFetch(`/api/hostapp/mypacks`, { refreshInterval: 0 })

    return data?.length ? <>
        <h2 className="font-semibold pt-4 container">Hosted by Me</h2>
        <p className='container text-gray-500 text-xs pb-6'>Tap on a property to edit details</p>
        <div className="flex overflow-x-scroll hide-scroll-bar px-4">
            <div className="flex flex-nowrap space-x-4">
                {data?.map((pack) => <SinglePack key={pack.uname} pack={pack} />)}
                <Link
                    href={"/partner"}
                    legacyBehavior>
                    <div className={`w-[65vw] lg:w-full`}>
                        <div className='relative h-[11rem] lg:h-80 w-full rounded-xl overflow-hidden bg-gray-100'>
                            <Image
                                className="object-cover"
                                layout="fill"
                                src={'covers/about.jpg'}
                            />
                            <div className="bg-gradient-to-t from-gray-800 absolute h-full w-full bottom-0"></div>
                            <div className="absolute flex flex-col space-y-2 items-center justify-center w-full h-full z-10">
                                <HiOutlinePlusCircle className="text-4xl text-white" />
                                <h2 className="text-white text-sm font-medium truncate">Host a New Property</h2>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    </> : null
}

export default MyProperties