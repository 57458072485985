'use client';

import Coupons from "components/cards/coupons";
import MyImage from "lib/image";
import { block } from "million/react-server";
import Link from "next/link";
import { useRef, useState } from "react";

const Banners = ({ padding }) => {
    const [showMore, setShowMore] = useState(false);
    const slider = useRef();
    const [page, setPage] = useState(1);

    const handleTouchEnd = (evt) => {
        setTimeout(() => {
            setPage(Math.round(slider.current.scrollLeft / slider.current.offsetWidth + 1));
        }, 400);
    }

    return <div className={padding || 'pt-10'}>
        <div>
            <div className="relative w-full flex space-x-4 snap-mandatory snap-x overflow-x-auto hide-scroll-bar scroll-smooth" ref={slider}>
                <div className="w-0 snap-end snap-always overflow-hidden relative shrink-0">
                </div>
                <div onTouchEnd={handleTouchEnd} className="w-[87vw] h-[165px] snap-end snap-always overflow-hidden relative shrink-0 rounded-xl bg-gray-100">
                    <Link href="/search">
                        <MyImage className="object-cover object-top" src="assets/banners/cashback_.jpg" fill />
                    </Link>
                </div>
                <div onTouchEnd={handleTouchEnd} className="w-[87vw] h-[165px] snap-end snap-always overflow-hidden relative shrink-0 rounded-xl bg-gray-100">
                    <MyImage onClick={() => setShowMore(true)} className="object-cover object-left" src="assets/banners/offers-hireacamp.png" fill />
                </div>
                <div className="w-0 snap-end snap-always overflow-hidden relative shrink-0">
                </div>
            </div>
            <div className="flex justify-center items-center space-x-1.5 mt-4">
                {[...Array(2).keys()].map((_, i) => page === i + 1 ? <div key={i} className={`px-2.5 py-0.5 rounded-full bg-gray-700 text-[0.5rem] text-white`}>{page}/2</div> : <div key={i} className={`w-2 h-2 rounded-full bg-gray-300`}></div>)}
            </div>
        </div>
        <Coupons showMore={showMore} setShowMore={setShowMore} />
    </div>
}

export default Banners