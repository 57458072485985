import Link from 'next/link'
import Image from "lib/image";

const categories = [{ label: 'Outdoor Movie Night', img: 'movie-night.jpg', url: 'movie-night', base64: 'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAADAAQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAeEAACAgICAwAAAAAAAAAAAAABAgAEAwUGERIxUf/EABUBAQEAAAAAAAAAAAAAAAAAAAME/8QAHBEAAgICAwAAAAAAAAAAAAAAAQMAAhIhIjGR/9oADAMBAAIRAxEAPwCDrPPc8d0l3Z57Fi09JFbI2Z+yFLAej8AiIjqVTAcR5InMuGW2e5//2Q==' }, { label: 'Live Performance', img: 'live-music.jpg', url: 'live-music', base64: 'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAEAAMDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAgEAABAwQCAwAAAAAAAAAAAAABAAIDBAUGESExByIj/8QAFAEBAAAAAAAAAAAAAAAAAAAABf/EABkRAAMAAwAAAAAAAAAAAAAAAAABAhEhMf/aAAwDAQACEQMRAD8AgbH5LqYbaxkuNYpUuD5PpNbAXH3doHRA46HHQCIiHqVl6FFw/9k=' }, { label: 'Swimming Pool', img: 'swimming-pool.jpg', url: 'swimming-pool', base64: 'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAADAAQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAeEAACAgICAwAAAAAAAAAAAAABAgAEAwUGERIxUf/EABUBAQEAAAAAAAAAAAAAAAAAAAME/8QAHBEAAgICAwAAAAAAAAAAAAAAAQMAAhIhIjGR/9oADAMBAAIRAxEAPwCDrPPc8d0l3Z57Fi09JFbI2Z+yFLAej8AiIjqVTAcR5InMuGW2e5//2Q==' }]

export function SinglePack({ pack }) {

    return (
        <Link key={pack.uname} href={`/activity/${pack.url}`}>
            <div className={`mb-6 w-[65vw] md:w-full`}>
                <div className='relative h-[11rem] md:h-64 w-full rounded-xl overflow-hidden border-2 border-white'>
                    {pack?.img ? <Image
                        className="object-cover"
                        layout="fill"
                        src={'assets/banners/' + pack?.img}
                        placeholder="blur"
                        blurDataURL={pack.base64}
                    /> : null}
                    <div className="bg-gradient-to-t from-gray-800 absolute h-16 w-full bottom-0"></div>
                    <div className="absolute bottom-0 left-0 w-full py-2 px-2 z-10">
                        <h2 className="text-white text-center truncate">{pack.label}</h2>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export const DesktopSpecial = () => {

    return <div className="">
        <div className='absolute h-96 left-0 bg-black w-screen z-[-1]'>
            <div className='absolute w-full h-full'>
                <Image layout="fill" className="object-cover" src={'assets/banners/special-attractions.jpg'} alt="footer" />
            </div>
        </div>
        <h2 className="text-3xl font-semibold text-white py-6 container relative z-10">Special Attractions</h2>
        <div className="container grid grid-cols-3 gap-4">
            {categories.map((pack, k) => <SinglePack key={k} pack={pack} />)}
        </div>
    </div>
}

const Special = () => {

    return <div className="bg-black relative mt-8">
        <div className='absolute w-full h-full'>
            <Image layout="fill" className="object-cover" src={'assets/banners/special-attractions.jpg'} alt="footer" />
        </div>
        <h2 className="font-medium text-white py-6 container relative z-10">Special Attractions</h2>
        <div className="flex overflow-x-scroll hide-scroll-bar px-4">
            <div className="flex flex-nowrap space-x-4">
                {categories.map((pack, k) => <SinglePack key={k} pack={pack} />)}
            </div>
        </div>
    </div>
}

export default Special