'use client';

import { DesktopHomePacks } from "components/packs/homepacks";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Image from 'lib/image'
import { menu } from "content/categories";

// const params = {
//     filter: ['type=package'], "sort": [
//         "rating:desc",
//         "_count:desc",
//     ]
// }

// const menu = [{ name: 'WOW!', api: '/api/external/ai/search', icon: 'aframes.png', params: { filter: [...params.filter, 'collections=3'] } },
// { name: 'Nearby', api: '/api/external/ai/search', icon: 'cabin.png', params: { filter: [...params.filter] } },
// {
//     name: 'Maharashtra', api: '/api/external/ai/search', icon: 'boathouse.png', params: { ...params, filter: [...params.filter, 'state=Maharashtra'] }
// },
// {
//     name: 'Uttarakhand', api: '/api/external/ai/search', icon: 'camping.png', params: { ...params, filter: [...params.filter, 'state=Uttarakhand'] }
// },
// {
//     name: 'Himachal', api: '/api/external/ai/search', icon: 'glamping.png', params: { ...params, filter: [...params.filter, 'state=Himachal'] }
// },
// {
//     name: 'Glamping', api: '/api/collection/magical', icon: 'villa.png', query: "type=packages", apiType: "direct"
// },
// {
//     name: 'Treks', api: '/api/external/ai/search', icon: 'premium.png', params: { ...params, filter: [...params.filter, 'pack_type=1'] }
// },
// {
//     name: 'Treks', api: '/api/external/ai/search', icon: 'tinyhomes.png', params: { ...params, filter: [...params.filter, 'pack_type=1'] }
// },
// {
//     name: 'Treks', api: '/api/external/ai/search', icon: 'dome.png', params: { ...params, filter: [...params.filter, 'pack_type=1'] }
// },
// ]

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} items-center justify-center bg-transparent realtive rounded-none shadow-none h-full z-10 before:absolute before:-left-4 before:top-0 before:h-full before:w-10 before:bg-gradient-to-r before:from-white before:to-transparent before:z-0 cursor-pointer`}
            style={{ ...style }}
            onClick={onClick}
        >
            <div className="w-7 h-7 rounded-full bg-white border border-gray-500 flex items-center justify-center relative z-10">
                <FaChevronRight className="text-sm" />
            </div>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} items-center justify-center bg-transparent realtive rounded-none shadow-none h-full z-10 after:absolute after:left-4 after:top-0 after:h-full after:w-10 after:bg-gradient-to-r after:from-white after:to-transparent after:z-0 cursor-pointer`}
            style={{ ...style }}
            onClick={onClick}
        >
            <div className="w-7 h-7 rounded-full bg-white border border-gray-500 flex items-center justify-center relative z-10">
                <FaChevronLeft className="text-sm" />
            </div>
        </div>
    );
}

export function DesktopCampsites() {
    const sliderRef = useRef();
    const [active, setActive] = useState(0)
    const [maxScroll, setMaxScroll] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)
    const [stick, setStick] = useState(false)
    const ref = useRef(null)
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["end end", "start start"]
    });

    
    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        if (latest > 0 && latest < 1) {
            setStick(true)
        }
        else {
            setStick(false)
        }
    })

    const params = {
        filter: ['type=package', []]
    }
    // params.sort = [
    //     "_count:desc",
    // ]

    if (menu[active].val) {
        params.filter[1].push(`${menu[active].type}=${menu[active].val}`)
        if (menu[active].synced) {
            params.filter[1].push(`synced=true`)
        }
    }

    useEffect(() => {
        if (sliderRef?.current?.scrollWidth) {
            setMaxScroll(sliderRef.current.scrollWidth - sliderRef.current.offsetWidth)
        }
    }, [sliderRef?.current?.scrollWidth])

    const handlePrevNext = (type, e) => {
        e.preventDefault();
        let scroll=0;
        if (type === 'prev') {
            scroll = sliderRef.current.scrollLeft - (sliderRef.current.offsetWidth * 4);
        } else {
            scroll = sliderRef.current.scrollLeft + (sliderRef.current.offsetWidth * 4);
        }
        sliderRef.current.scrollLeft = scroll;
        setScrollLeft(scroll)
    }

    return (
        <div ref={ref} className="pt-16 body-p-x">
            <div className="mb-10 relative">
                <div className="flex overflow-x-scroll px-4 hide-scroll-bar scroll-smooth" ref={sliderRef}>
                <div className="flex flex-nowrap space-x-24">
                    {menu.map((item, index) => (
                        <motion.div whileTap={{ scale: 0.9 }} key={index} animate={index === active ? 'active' : 'inactive'} variants={variants} className="flex flex-col items-center transition duration-100 relative cursor-pointer" onClick={() => setActive(index)}>
                            <Image src={`assets/icons/categorons/${item.icon}`} width={32} height={32} className={`mb-2 w-auto transition duration-100 ${index === active ? 'opacity-100' : 'opacity-60'}`} style={{ height: 20 }} />
                            <p className={`text-xs ${index === active ? 'font-semibold' : 'font-medium'} capitalize whitespace-nowrap mb-1`}>{item.name}</p>
                            {index === active ? <motion.div initial={{ width: '1.5rem', background: '#f1f1f1' }} animate={{ width: '50%', background: '#000000' }} className="absolute h-0.5 border rounded-full border-black border-opacity-20 bottom-0" /> : null}
                        </motion.div>))}
                </div>
                </div>
                <SamplePrevArrow onClick={(e) => handlePrevNext('prev', e)} className={`absolute top-0 bottom-0 -left-8 z-10 ${scrollLeft <= 0 ? 'hidden':'flex'}`} />
                <SampleNextArrow onClick={(e) => handlePrevNext('next', e)} className={`absolute top-0 bottom-0 -right-8 z-10 ${scrollLeft >= maxScroll ? 'hidden':'flex'}`} />
            </div>
            <DesktopHomePacks key={active} params={params} apiType='' />
        </div>
    );
}

const variants = {
    active: {
        color: 'rgb(38 38 38)'
    },
    inactive: {
        color: 'rgb(115 115 115)',
    }
};

function Campsites() {
    const [active, setActive] = useState(0)

    return (
        <div className="pt-10">
            <div className="flex overflow-x-scroll px-4 pb-6 hide-scroll-bar">
                <div className="flex flex-nowrap space-x-12">
                    {menu.map((item, index) => (
                        <motion.div whileTap={{ scale: 0.9 }} key={index} animate={index === active ? 'active' : 'inactive'} variants={variants} className="flex flex-col items-center" onClick={() => setActive(index)}>
                            <Image src={`assets/icons/categoryicons/${item.icon}`} width={32} height={32} className="mb-1 w-auto" style={{height: 24}} />
                            <p className={`text-sm ${index === active ? 'font-semibold' : 'font-medium'} capitalize`}>{item.name}</p>
                            {index === active ? <motion.div initial={{ background: '#f1f1f1' }} animate={{ background: '#000000' }} className="w-8 h-0.5 mt-1 border rounded-full border-black border-opacity-20" /> : null}
                        </motion.div>))}
                </div>
            </div>
            <HomePacks key={active} api={menu[active].api} params={menu[active].params} query={menu[active].query} apiType={menu[active].apiType} />
        </div>
    );
}

export default Campsites;