export function round_decimals(num, dec) {
    num = Math.round(num + 'e' + dec)
    return Number(num + 'e-' + dec)
}
export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}
export function extractYoutubeId(url) {
    const match = url.match(/(?:youtube.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu.be\/)([^"&?/ ]{11})/);
    return match ? match[1] : url;
}

export function formatMealsIncluded(meals) {
    meals.sort((a, b) => a.localeCompare(b))
    const mealsArr = meals.map((meal) => {
        if (meal === "Barbeque") {
            return "BBQ";
        } else {
            return meal;
        }
    });

    if (mealsArr.length === 1) {
        return `${mealsArr[0]} included`
    }
    else if (mealsArr.length === 2) {
        return mealsArr[0] + ' and ' + mealsArr[1]
    }
    else if (mealsArr.length === 3) {
        return mealsArr[0] + ', ' + mealsArr[1] + ' and ' + mealsArr[2]
    }
    else if (mealsArr.length === 4) {
        return mealsArr[0] + ', ' + mealsArr[1] + ', ' + mealsArr[2] + ' and ' + mealsArr[3]
    }
    else if (mealsArr.length === 5) {
        return mealsArr[0] + ', ' + mealsArr[1] + ', ' + mealsArr[2] + ', ' + mealsArr[3] + ' and ' + mealsArr[4]
    }
}

export function firstDayofYear(dayOfWeek) {
    const year = 2023; // replace with desired year

    // create a new Date object for January 1st of the given year
    const date = new Date(year, 0, 1);

    // loop through each day of the year until we find the first occurrence of the desired day of the week
    while (date.getDay() !== dayOfWeek) {
        date.setDate(date.getDate() + 1);
    }

    // log the result
    //change timezone to Asia/Kolkata
    date.setHours(date.getHours() + 6);
    return date;
}

export function arrToObj(input) {
    const output = {};

    input.forEach(item => {
        // Split each string at the '=' sign
        const [key, value] = item.split('=');

        // Remove leading and trailing spaces
        const trimmedKey = key.trim();
        const trimmedValue = value.trim();

        // Remove quotes around the value if present
        const cleanedValue = trimmedValue.replace(/^['"]|['"]$/g, '');

        // Add the key-value pair to the object
        output[trimmedKey] = cleanedValue;
    });

    return output;
}

export function ordinal(n) {
    var s = ["th", "st", "nd", "rd"];
    var v = n%100;
    return n + (s[(v-20)%10] || s[v] || s[0]);
}