'use client';

import Image from 'lib/image'
import Link from 'next/link';
import dynamic from 'next/dynamic';

const Player = dynamic(() => import('@lottiefiles/dotlottie-react').then(mod => mod.DotLottieReact), { ssr: false });

export default function LabelBottomNavigation({ active, contact }) {

    const buttons = [
        { name: 'Explore', icon: 'logo', route: '/' },
        { name: 'Search', icon: 'search', route: '/search' },
        { name: 'Wishlist', icon: 'wishlist', route: '/wishlist' },
        { name: 'More', icon: 'more', route: '/profile' },
    ];

    return <div>
        <div className="h-16"></div>
        <nav className={`fixed rounded-t-2xl w-full bottom-0 z-20 bg-white backdrop-filter backdrop-blur-lg bg-opacity-80 border-t border-gray-200 transition-all duration-500 ease-in-out`}>
            <div className="max-w-5xl mx-auto px-4">
                <div className="flex items-center justify-between h-16">
                    {buttons.map((button, k) => <Link
                        key={button.route}
                        href={button.route}
                        className="px-3 flex flex-col items-center"
                        style={active === k ? { color: '#48BE4C' } : { color: '#8a8a8a' }}>
                        <div style={{width:32, height: 32}}>
                            {active === k ? <Player
                                keepLastFrame
                                autoplay src={'/assets/animated/' + button.icon + '.lottie'} style={{ height: '32px', width: '32px' }}>
                            </Player> :
                                <Image src={'assets/animated/' + button.icon + '.png'} width={32} height={32} alt={button.name} />}
                        </div>
                        <span className={`text-xs ${active === k ? 'font-medium' : ''}`}>{button.name}</span>

                    </Link>)}
                </div>
            </div>
        </nav>
    </div>;
}
