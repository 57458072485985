import React, { useContext, useEffect } from 'react';
import Link from 'next/link'
import dynamic from "next/dynamic";

//Images
import Image from "lib/image";
import { pluralize } from 'lib/utilities';
import { useFetch } from 'lib/useInfiniteFetch';
import { Context } from 'components/context';
import { sendGTMEvent } from '@next/third-parties/google'

const Slider = dynamic(() => import('components/utilities/slider'), {
    ssr: false
})

export function Placeholder() {
    let rows = [];
    for (var i = 0; i < 5; i++) {
        rows.push(<div key={i} className="h-80 w-full bg-gray-200 rounded-xl animate-pulse mr-3"></div>)
    }
    return <div className="flex">{rows}</div>
}

export default function PcHimalayas() {
    const context = useContext(Context)
    const { data } = useFetch(context?.state?.currentLocation?.lat ? `/api/city/nearme?limit=25&lat=${context.state?.currentLocation?.lat}&lng=${context.state?.currentLocation?.lng}` : '/api/city/nearme?limit=25')

    useEffect(() => {
        if (!context.state?.currentLocation?.lat) {
            navigator.geolocation.getCurrentPosition((position) => {
                context.dispatch({ currentLocation: { lat: position.coords.latitude, lng: position.coords.longitude } })
                sendGTMEvent({ event: 'Location_allowed', value: 'xyz' })
            }, (error) => {
                console.log(error)
            })
        }
    }, [])

    let rows1 = data?.hits?.map((pack, k) => {
        return (
            <div key={k}>
                <Link href={"/city/[cityname]"} as={"/city/" + pack.uname} legacyBehavior>
                    <div className="w-full h-80 relative bg-gray-100 rounded-xl overflow-hidden cursor-pointer">
                        {pack?.img?.src ? <Image
                            sizes="20vw"
                            alt={"Camping in " + pack.uname}
                            layout="fill"
                            className="w-full h-full object-cover shadow"
                            placeholder="blur"
                            blurDataURL={pack.img.blurData}
                            src={'uploads/' + pack.img.src}
                        /> : null}
                        <div class="bg-gradient-to-t from-gray-800 absolute h-40 w-full bottom-0"></div>
                        <div className="flex flex-col justify-end absolute top-0 w-full h-full pb-5 px-6">
                            <h3 class="text-2xl font-medium text-white capitalize">{pack.name}</h3>
                            <p class="text-white">{pluralize(pack._count, 'Property', 'ies')}</p>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
    );
    return <div className="body-p-x">
        <h3 className="text-3xl font-semibold text-gray-600 pt-12 pb-8">
            Nearby popular destinations
        </h3>
        {data?.hits ? <Slider settings={{ slidesToShow: 5 }}>
            {rows1}
        </Slider> : <Placeholder />}
    </div>
}