'use client';

import React, { useState } from 'react';
import Link from 'next/link'
import styles from './trek.scss';

//Images
import Image from "lib/image";
import { useFetch } from 'lib/useInfiniteFetch';
import { block, For } from "million/react-server";

var xDown = null;
var yDown = null;

function getTouches(evt) {
    return evt.touches ||             // browser API
        evt.originalEvent.touches; // jQuery
}

function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
};

const TrekPacks = () => {
    const [active, setActive] = useState(0)
    const params = {
        filter: ['type=state']
    }
    const { data } = useFetch(`/api/external/ai/search?limit=40&params=${JSON.stringify(params)}`)

    const length = data?.length || 0

    const handleTouchMove = (evt) => {
        if (!xDown || !yDown) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;


        if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
            if (xDiff > 0) {
                setActive((length + active + 1) % length)
            } else {
                setActive((length + active - 1) % length)
            }
        } else {
            if (yDiff > 0) {
                /* up swipe */
            } else {
                /* down swipe */
            }
        }
        /* reset values */
        xDown = null;
        yDown = null;
    }

    const placeholder = Array(3).fill().map((pack, k) => {
        let className = "center-trek";
        if (k === 1)
            className = "next-trek";
        else if (k === 2)
            className = "prev-trek";
        return (
            <div key={k} className={"absolute destination-trek overflow-hidden shadow " + className}>
            </div>
        );
    })

    return <>
        <h2 className="font-semibold pt-8 pb-2 container">Stay Anywhere</h2>
        <div className="flex items-center justify-center overflow-hidden destinationswrapper-trek">
            {data?.length ? <For each={data}>
                {(pack, k) => {
                    const uname = pack.uname;
                    let className = "hideous-trek";
                    if (k === (length + active) % length)
                        className = "center-trek";
                    else if (k === (length + active + 1) % length)
                        className = "next-trek";
                    else if (k === (length + active - 1) % length)
                        className = "prev-trek";
                    return (
                        <Link key={pack.id} href={`/state/${uname}`} onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
                            onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
                            className={"absolute destination-trek overflow-hidden shadow " + className}>
                            {pack?.img?.src ? <Image
                                sizes="46vw"
                                alt={"Camping in " + uname}
                                layout="fill"
                                className="w-full h-full object-cover"
                                placeholder="blur"
                                blurDataURL={pack.img.blurData}
                                src={'uploads/' + pack.img.src}
                            /> : null}
                            <div className="absolute bottom-0 left-0 w-full py-2 px-2 bg-gray-600 backdrop-filter backdrop-blur-lg bg-opacity-40">
                                <h2 className="text-white text-sm font-medium">{pack.name}</h2>
                            </div>
                        </Link>
                    );
                }}
            </For> : placeholder}
        </div>
        <style global jsx>{styles}</style>
    </>
}

export default TrekPacks