'use client';

import React, { useContext, useEffect, useState } from 'react';
import Link from 'next/link'
import styles from './himalayas.scss';
import { sendGTMEvent } from '@next/third-parties/google'

//Images
import Image from "lib/image";
import { useFetch } from 'lib/useInfiniteFetch';
import { block } from "million/react-server";
import { Context } from 'components/context';

var xDown = null;
var yDown = null;

function getTouches(evt) {
    return evt.touches ||             // browser API
        evt.originalEvent.touches; // jQuery
}

function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
};

const SpotifyCards = () => {
    const context = useContext(Context)
    const [active, setActive] = useState(0)
    const { data } = useFetch(context?.state?.currentLocation?.lat ? `/api/city/nearme?limit=25&lat=${context.state?.currentLocation?.lat}&lng=${context.state?.currentLocation?.lng}` : '/api/city/nearme?limit=25')

    useEffect(() => {
        if (!context?.state?.currentLocation?.lat) {
            navigator.geolocation.getCurrentPosition((position) => {
                context.dispatch({ currentLocation: { lat: position.coords.latitude, lng: position.coords.longitude } })
                sendGTMEvent({ event: 'Location_allowed', value: 'xyz' })
            }, (error) => {
                console.log(error)
            })
        }
    }, [])

    const length = data?.hits?.length || 0

    const handleTouchMove = (evt) => {
        if (!xDown || !yDown) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;


        if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
            if (xDiff > 0) {
                setActive((length + active + 1) % length)
            } else {
                setActive((length + active - 1) % length)
            }
        } else {
            if (yDiff > 0) {
                /* up swipe */
            } else {
                /* down swipe */
            }
        }
        /* reset values */
        xDown = null;
        yDown = null;
    }

    const rows1 = data?.hits?.map((pack, k) => {
        const uname = pack.uname;
        let className = "hideous";
        if (k === (length + active) % length)
            className = "center";
        else if (k === (length + active + 1) % length)
            className = "next";
        else if (k === (length + active - 1) % length)
            className = "prev";
        else if (k === (length + active + 2) % length)
            className = "next-next";
        else if (k === (length + active - 2) % length)
            className = "prev-prev";
        else if (k === (length + active + 3) % length)
            className = "next-next-next";
        else if (k === (length + active - 3) % length)
            className = "prev-prev-prev";
        return (
            <Link key={k} href={`/city/${uname}`} onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
                onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
                className={"absolute destination overflow-hidden shadow " + className}>
                {pack?.img?.src ? <Image
                    sizes="46vw"
                    alt={"Camping in " + uname}
                    layout="fill"
                    className="w-full h-full object-cover"
                    placeholder="blur"
                    blurDataURL={pack.img.blurData}
                    src={'uploads/' + pack.img.src}
                /> : null}
                <div className="flex items-center justify-center absolute w-full h-full z-10 top-0 caption">
                    <h3 className={`capitalize font-caveat text-white text-2xl leading-6 px-2 text-center`}>{pack.name.split(" ")[0]}</h3>
                </div>
            </Link>
        );
    }
    );
    const placeholder = Array(7).fill().map((pack, k) => {
        let className = "center";
        if (k === 1)
            className = "next";
        else if (k === 2)
            className = "prev";
        else if (k === 3)
            className = "next-next";
        else if (k === 4)
            className = "prev-prev";
        else if (k === 5)
            className = "next-next-next";
        else if (k === 6)
            className = "prev-prev-prev";
        return (
            <div key={k} className={"absolute destination overflow-hidden shadow animate-pulse " + className}>
            </div>
        );
    })

    return <>
        <h2 className="font-semibold pt-8 pb-6 container">Trending Locations</h2>
        <div className="flex items-center justify-center overflow-hidden destinationswrapper h-[110px]">
            {data?.hits ? rows1 : placeholder}
        </div>
        <style global jsx>{styles}</style>
    </>
}

export default SpotifyCards