const { useState, useContext } = require("react");
import { Context } from 'components/context';
import useUser from 'lib/useUser';

export default function Heart({ isPack, uname }) {
  const [heart, setHeart] = useState(false);
  const { user } = useUser()
  const context = useContext(Context)
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (user?.isLoggedIn) {
      fetch('/api/wishlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'credentials': 'include'
        },
        body: JSON.stringify({ uname, remove: heart?true:false })
      })
      setHeart(!heart)
    }
    else
      context.dispatch({ type: 'login' });
  }

  return <>
    <div onClick={handleClick} className={`relative flex items-center justify-center ${isPack ? 'bg-white shadow' : ''} rounded-full w-9 h-9`}>
      {heart ? <div className={`heart ${heart ? 'filled is_animating' : ''}`}></div> : <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className={`text-lg ${isPack ? '' : 'text-white'}`} height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path></svg>}
    </div>
    <style global jsx>{`
                        .heart {
                            position: absolute;
                            cursor: pointer;
                            height: 60px;
                            width: 60px;
                            background-image:url( ${`${process.env.NEXT_PUBLIC_CDN}assets/icons/heart_animation.png`});
                            background-position: left;
                            background-repeat:no-repeat;
                            background-size:2900%;
                          }
                          .filled {
                            background-position:right;
                          }
                          
                          .is_animating {
                            animation: heart-burst .8s steps(28) 1;
                          }
                          
                          @keyframes heart-burst {
                            from {background-position:left;}
                            to { background-position:right;}
                          }
                          `}</style>
  </>
}