'use client';

import Link from 'next/link'
import Image from "lib/image";
import { FaStar } from 'react-icons/fa'
import useSWRInfinite from 'swr/infinite'
import MySlider from 'components/utilities/slider';
import InfiniteScroll from 'react-swr-infinite-scroll';
import useSWR from 'swr';
import { fetcher, useFetch } from 'lib/useInfiniteFetch';

export function Placeholder() {
    let rows = [];
    for (var i = 0; i < 4; i++) {
        rows.push(<div key={i} className="mb-4 w-[68vw] md:w-[18vw] animate-pulse bg-gray-200 relative h-[11rem] md:h-64 rounded-xl shadow-lg overflow-hidden">
            <div className="absolute bottom-0 left-0 w-full py-3 px-2 bg-gray-600 backdrop-filter backdrop-blur-lg bg-opacity-40">
                <div className="h-4 bg-gray-200 rounded mb-2 w-3/4">
                </div>
                <div className="flex justify-between items-center mt-1">
                    <div className="w-16 h-2 bg-gray-200 rounded"></div>
                    <div className="rounded-full bg-gray-200 h-4 w-4"></div>
                </div>
            </div>
        </div>)
    }
    return rows
}

export function SinglePack({ pack, slider = true, apiType }) {

    return (
        <Link
            key={pack.uname}
            href={"/[packname]"}
            as={"/" + pack.uname}
            legacyBehavior>
            <div className={`w-[65vw] lg:w-full`}>
                <div className='relative h-[11rem] lg:h-80 w-full rounded-xl overflow-hidden bg-gray-100'>
                    {pack?.img ? <Image
                        className="object-cover"
                        layout="fill"
                        placeholder="blur"
                        blurDataURL={pack?.img?.blurData}
                        src={'uploads/' + pack?.img?.src}
                    /> : null}
                    <div className="bg-gradient-to-t from-gray-800 absolute h-16 w-full bottom-0"></div>
                    <div className="absolute bottom-0 left-0 w-full py-2 px-2 z-10">
                        <h2 className="text-white text-sm font-medium truncate">{pack.fancyname}</h2>
                        <div className='font-extralight flex items-center justify-between w-full text-sm text-white'>
                            <p className="truncate">{pack.city?.name || pack.city}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export function DesktopSinglePack({ pack, slider = true, apiType }) {

    return (
        <Link
            key={pack.uname}
            href={"/[packname]"}
            as={"/" + pack.uname}
            legacyBehavior>
            <div className={`w-full cursor-pointer`}>
                <div className='relative h-64 w-full rounded-xl overflow-hidden bg-gray-100'>
                    {pack?.img ? <Image
                        className="object-cover"
                        layout="fill"
                        placeholder="blur"
                        blurDataURL={pack?.img?.blurData}
                        src={'uploads/' + pack?.img?.src}
                    /> : null}
                    <div className="bg-gradient-to-t from-gray-800 absolute h-32 w-full bottom-0"></div>
                    <div className="absolute top-4 left-0 py-1 px-2.5 z-10 rounded-r-full bg-yellow-700 text-sm bg-cover font-medium" style={{ background: `url(${process.env.NEXT_PUBLIC_CDN}assets/banners/luxury-bg.jpg)`, color: '#99623F' }}>
                        Luxury
                    </div>
                    <div className="absolute bottom-0 left-0 w-full py-4 px-3 z-10">
                        <h2 className="text-white font-medium truncate">{pack.fancyname}</h2>
                        <div className='flex items-center justify-between w-full text-sm text-gray-100'>
                            <p className="truncate">{pack.city?.name || pack.city}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export const DesktopMagicalPacks = ({ api, query, params, limit = 4 }) => {

    const { data } = useFetch(`/api/collection/magical?type=packages&limit=4`, { refreshInterval: 0 })

    return <div className="">
        <div className='absolute h-[28rem] left-0 bg-yellow-50 w-screen z-[-1]'></div>
        <h2 className="text-3xl font-semibold text-gray-600 pb-0.5 pt-10">Oh. So. Magical</h2>
        <p className='pb-8 text-gray-500 text-lg'>Feel it, Believe it</p>
        <div className="grid grid-cols-4 gap-4">
            {data?.map((pack) => <DesktopSinglePack key={pack.uname} pack={pack} />)}
        </div>
    </div>
}

const MagicalPacks = ({ limit = 10 }) => {

    const swr = useSWRInfinite((index, prev) => `/api/collection/magical?type=packages&page=${index + 1}&limit=${limit}`, fetcher, { refreshInterval: 0, revalidateFirstPage: false, initialSize: 1 })

    return <>
        <h2 className="font-semibold pt-8 pb-6 container relative z-10">Oh. So. Magical</h2>
        <div className="flex overflow-x-scroll hide-scroll-bar px-4">
            <div className="flex flex-nowrap space-x-4">
                <InfiniteScroll
                    swr={swr}
                    loadingIndicator={<div className="flex flex-nowrap space-x-4"><Placeholder /></div>}
                    endingIndicator=""
                    isReachingEnd={(swr) =>
                        swr.error || swr.data?.[0]?.length === 0 || swr.data?.[swr.data?.length - 1]?.length < limit
                    }
                >
                    {(response) => <>
                        {response?.map((pack) => <SinglePack key={pack.uname} pack={pack} />)}
                    </>
                    }
                </InfiniteScroll >
            </div>
        </div>
    </>
}

export default MagicalPacks