'use client';

import Link from 'next/link'
import dynamic from 'next/dynamic'
import { AiOutlineLogin } from 'react-icons/ai'
import { BsCalendar2Month, BsKey } from 'react-icons/bs'
import { BiSupport, BiLogOutCircle } from 'react-icons/bi'
import { FaRegHandshake } from 'react-icons/fa'
import { GrBlog } from 'react-icons/gr'
import { IoPersonOutline } from 'react-icons/io5'
import { HiChevronRight, HiOutlineCreditCard } from 'react-icons/hi'
import useSWR from 'swr'
import SyncLoader from "react-spinners/SyncLoader";
import { useState } from 'react'
import MyProperties from 'components/cards/myprops'
import { useFetch } from 'lib/useInfiniteFetch';

const Backdrop = dynamic(() => import('@material-ui/core/Backdrop'), { ssr: false })

export function Stats({ is_admin }) {

    const { data, error } = useFetch(`/api/hostapp/stats`)

    return data?.success ? <div className='pl-4 pt-6'>
        <div className="flex overflow-x-scroll hide-scroll-bar">
            <div className="flex flex-nowrap space-x-4 mb-4">
                <Link href="/host/bookings" legacyBehavior>
                    <div className="shadow rounded-lg p-3">
                        <p className='font-medium'>
                            <span className='font-bold text-4xl text-primary'>
                                {data?.camp_nights?.upcoming}
                            </span>
                            &nbsp; Bookings
                        </p>
                        <div className="text-xs text-gray-600 mt-2">Upcoming</div>
                    </div>
                </Link>

                {is_admin ? <div className="shadow rounded-lg p-3">
                    <p className='font-medium'>
                        <span className='font-bold text-4xl'>
                            {data?.orders?.upcoming}
                        </span>
                        &nbsp; Orders
                    </p>
                    <div className="text-xs text-gray-600 mt-2">Upcoming</div>

                </div> : <div className="shadow rounded-lg p-3">
                    <p className='font-medium'>
                        <span className='font-bold text-4xl'>
                            {data?.camp_nights?.total}
                        </span>
                        &nbsp; Bookings
                    </p>
                    <div className="text-xs text-gray-600 mt-2">Total</div>

                </div>}

                {is_admin ? <div className="shadow rounded-lg p-3">
                    <p className='font-medium'>
                        <span className='font-bold text-4xl text-red-500'>
                            {data?.orders?.unconfirmed}
                        </span>
                        &nbsp; Orders
                    </p>
                    <div className="text-xs text-gray-600 mt-2">Unconfirmed</div>

                </div> : null}

            </div>
        </div>
    </div> : null
}


export default function Host({ user, is_admin }) {
    const [open, setOpen] = useState(false)

    const handleLogout = () => {
        setOpen(true)
        fetch('/api/auth/logout')
            .then(res => res.json())
            .then(data => {
                setOpen(false)
                window.location.reload()
            })
            .catch(err => {
                setOpen(false)
                console.log(err)
            })
    }

    return <div className="mt-4 z-10">
        <Backdrop style={{ zIndex: 1 }} open={open}>
            <SyncLoader
                color="#ffffff"
                size={16}
                loading={true}
            />
        </Backdrop>
        <div className="container">
            <Stats is_admin={is_admin} />
        </div>
        <MyProperties />
        <div className="container">
            <div className='px-4 py-4 border-b'>
            <Link href="/host/availability  " legacyBehavior>
                    <div className='flex items-center justify-between py-4'>
                        <div className='flex items-center'>
                            <BsCalendar2Month/>
                            <p className="pl-3 font-medium text-sm">Change Availability / Block Dates</p>
                        </div>
                        <HiChevronRight />
                    </div>
                </Link>
                <Link href="/host/bookings" legacyBehavior>
                    <div className='flex items-center justify-between py-4'>
                        <div className='flex items-center'>
                            <BsKey />
                            <p className="pl-3 font-medium text-sm">Upcoming Bookings</p>
                        </div>
                        <HiChevronRight />
                    </div>
                </Link>
                {is_admin ? <Link href="/backoffice" legacyBehavior>
                    <div className='flex items-center justify-between py-4'>
                        <div className='flex items-center'>
                            <HiOutlineCreditCard />
                            <p className="pl-3 font-medium text-sm">Backoffice</p>
                        </div>
                        <HiChevronRight />
                    </div>
                </Link> : <Link href="/host/banks" legacyBehavior>
                    <div className='flex items-center justify-between py-4'>
                        <div className='flex items-center'>
                            <HiOutlineCreditCard />
                            <p className="pl-3 font-medium text-sm">Bank Accounts</p>
                        </div>
                        <HiChevronRight />
                    </div>
                </Link>}
            </div>
            <div className='px-4 py-4 border-b'>
                <a href="tel:7060015989">
                    <div className='flex items-center justify-between py-4'>
                        <div className='flex items-center'>
                            <BiSupport />
                            <p className="pl-3 font-medium text-sm">Contact Customer Support</p>
                        </div>
                        <HiChevronRight />
                    </div>
                </a>

            </div>
            <div className='px-4 py-4'>
                <Link href="/campzine" legacyBehavior>
                    <div className='flex items-center justify-between py-4'>
                        <div className='flex items-center'>
                            <GrBlog />
                            <p className="pl-3 font-medium text-sm">Campzine</p>
                        </div>
                        <HiChevronRight />
                    </div>
                </Link>
                {user?.isLoggedIn ? <div onClick={handleLogout} className='flex items-center justify-between py-4'>
                    <div className='flex items-center'>
                        <BiLogOutCircle />
                        <p className="pl-3 font-medium text-sm">Logout</p>
                    </div>
                    <HiChevronRight />
                </div> : null}
            </div>
        </div>
    </div>
}