import Image from "lib/image";
import { FaChevronLeft, FaCopy } from "react-icons/fa";
import { MdOutlineClose } from 'react-icons/md'
import { useContext } from "react";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import TextField from '@material-ui/core/TextField';
import useSWR from "swr";
import { UserAgentProvider, UserAgent } from '@quentin-sommer/react-useragent'
import MobileDialog from "components/utilities/mobiledialog";
import { useFetch } from "lib/useInfiniteFetch";
import { Context } from "components/context";

export const Coupon = ({ d, k, onClose }) => {
    let color = 'hac'
    if (k % 5 === 1) color = 'blue'
    if (k % 5 === 2) color = 'green'
    if (k % 5 === 3) color = 'red'
    if (k % 5 === 4) color = 'yellow'

    const handleCopy = () => {
        navigator.clipboard.writeText(d.code)
        onClose()
    }

    return <div className="w-full min-w-72">
        <div
            className={`bg-gradient-to-r rounded-lg from-${color}-100 to-${color}-200`}>
            <div className="py-4 px-6 flex flex-col space-y-2 items-center h-full">
                <p className={`text-center font-semibold text-${color}-600`}>
                    {d.type === 1 ? 'Flat ₹' : ''}{d.discount}{d.type === 2 ? '%' : ''} Off {d.per_person === 1 ? 'Per Person ' : ''} {d.max_discount ? `up to ₹${d.max_discount}` : ''} {d.min_person ? `on ${d.min_person} ${d.max_person?(d.max_person === d.min_person?'':`to ${d.max_person}`):'or more People'}` : ''}
                </p>
                <div className="flex justify-between space-x-2">
                    <p className={`text-xl font-bold text-${color}-800 uppercase border border-dashed p-1 rounded border-${color}-600`}>{d.code}</p>
                    <p onClick={handleCopy} className={`bg-gray-700 text-sm text-white px-2 flex items-center rounded-lg`}>Copy Code</p>
                </div>
            </div>
        </div>
    </div>
}

const Coupons = ({ showMore, setShowMore }) => {

    const { data, error } = useFetch('/api/camp/coupon');

    return <MobileDialog open={showMore} onClose={() => setShowMore(false)}>

        <nav
            className="flex body-p-x pt-4">
            <div onClick={() => setShowMore(false)} className='shadow rounded-xl p-3 bg-gray-100 mr-6'>
                <MdOutlineClose className="text-2xl cursor-pointer" />
            </div>
        </nav>
        <div className="body-p-x max-w-sm overflow-x-hidden overflow-y-scroll" onTouchStart={(e)=>e.stopPropagation()}>
            <div className="mt-4 from-hac-100 to-hac-200 from-red-100 to-red-200 from-green-100 to-green-200 from-blue-100 to-blue-200 from-yellow-100 to-yellow-200 text-hac-800 text-red-800 text-green-800 text-blue-800 text-yellow-800 text-hac-600 text-red-600 text-green-600 text-blue-600 text-yellow-600 border-hac-600 border-red-600 border-green-600 border-blue-600 border-yellow-600 bg-hac-400 hidden"></div>
            <div className="flex flex-col space-y-4 items-center my-4">
                {data?.map((d, k) => <Coupon key={k} d={d} k={k} onClose={() => setShowMore(false)} />)}
            </div>

        </div>
    </MobileDialog>
}

export default Coupons