'use client';

import Image from 'lib/image';
import Link from 'next/link'
import Himalayas from "components/cards/desktop";
import Desktopfooter from "../navs/desktopfooter";
import Header from 'components/navs/header';
import { GrLocation } from 'react-icons/gr'
import { BiSearchAlt } from 'react-icons/bi'
import { DesktopCampsites } from './campsites';
import { DesktopMagicalPacks } from 'components/cards/magical';
import { DesktopSpecial } from './special';
import { useInView, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { words } from 'content/homepage';

function ChangingTypes() {
    const [index, setIndex] = useState(0)

    const item = {
        visible: {
            opacity: 1,
            y: 0,
        },
        hidden: {
            opacity: 0,
            y: 20,
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((index) => {
                if (index === words.length - 1) {
                    return 0
                } else {
                    return index + 1
                }
            })
        }, 1500)
        return () => clearInterval(interval)
    }, [])

    return (
        <motion.div initial="hidden" animate="visible">
            <motion.div key={index} variants={item} className="text-6xl font-bold text-white">{words[index]}</motion.div>
        </motion.div>
    )
}

function Desktop() {
    const [initial, setInitial] = useState(true)
    const ref = useRef(null)
    const isInView = useInView(ref, { once: false })

    const item = {
        visible: {
            opacity: 1,
            y: 0,
        },
        hidden: {
            opacity: 0.5,
            y: -50,
        }
    }

    useEffect(() => {
        if (initial) {
            setInitial(false)
        }
    }, [])

    return (
        <div>
            <Header transparent nosearch />
            {!isInView && !initial ? <motion.div variants={item} initial="hidden" animate="visible" className="fixed top-0 z-20 ">
                <Header glass fixed />
            </motion.div> : null}
            <div className="relative w-screen h-[36rem]" style={{ minHeight: '65vh' }}>
                <Image src={'assets/banners/hireacamp.jpg'} alt="Book Camping in India" layout="fill" objectFit="cover" />
                <div className='bg-gray-800 absolute w-full h-full opacity-25'></div>
                <div className="absolute w-full bottom-0">
                    <div className='container flex flex-col justify-center items-center pb-16'>
                        <Image src="assets/icons/hireacamp-logo-white.png" width={116} height={135} alt="Hire A Camp" />
                        <h1 className='hidden'>Book Camping, Glamping, Treks and Cottages in India with HireACamp</h1>
                        <h2 className="text-5xl text-white pt-12">Discover the world of</h2>
                        {initial ? <h2 className="text-6xl font-bold text-white">Unique Stays</h2> : <ChangingTypes />}
                        <Link href="/search" ref={ref} className="rounded-full w-full h-16 bg-white shadow px-4 flex items-center justify-between mt-12 cursor-pointer">
                            <div className="flex space-x-4 items-center">
                                <GrLocation className='text-muted text-2xl' />
                                <p className='text-muted text-sm'>Search by City, State or Stay Name</p>
                            </div>
                            <div className="w-32 h-12 px-6 py-3 bg-primary rounded-full flex items-center justify-center text-white">
                                <BiSearchAlt className='text-2xl pr-1' />
                                Search
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <Himalayas desktop />
            <DesktopCampsites />
            <Desktopfooter />
        </div>
    );
}

export default Desktop;
