'use client';

import Image from "lib/image"
import useUser from "lib/useUser";
import dynamic from "next/dynamic";
import Link from "next/link";
import LazyLoad from 'react-lazyload';

const Player = dynamic(() => import('@lottiefiles/dotlottie-react').then(mod => mod.DotLottieReact), { ssr: false });

function Greeting() {
    const { user } = useUser()
    const currentTime = new Date().getHours();

    return <Link href="/profile" legacyBehavior>
        <div className="flex items-center body-p-x pt-4">
            <Image className="rounded-full" src="assets/icons/avatar.jpg" width={48} height={48} alt="HireACamp Avatar" />
            <div className="ml-2">
                <p className="text-sm font-semibold text-gray-600">Hi, {user?.isLoggedIn ? user.name.split(" ")[0] : 'Camper'}!</p>
                <div className="flex items-center">
                    <p className="text-xs text-gray-400 mr-1">{currentTime >= 0 && currentTime <= 12 ? 'Good Morning' : (currentTime > 12 && currentTime <= 16 ? 'Good Afternoon' : 'Good Evening')}</p>
                    <LazyLoad unmountIfInvisible>
                        <Player
                            autoplay
                            loop
                            src={currentTime >= 0 && currentTime <= 16 ? "/assets/animated/sun.lottie" : "/assets/animated/moon.lottie"}
                            style={{ height: '24px', width: '24px' }}
                        >
                        </Player>
                    </LazyLoad>
                </div>
            </div>
        </div>
    </Link> 
}

export default Greeting