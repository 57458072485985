export const menu = [{
    name: 'Popular', type: 'collections', synced: true, val: 3, icon: 'tinyhome.png',
},
{
    name: 'Glamping', type: 'categories', val: 1, icon: 'glamp.png',
},
{
    name: 'Villa', type: 'categories', val: 2, icon: 'villa.png',
},
{
    name: 'Cabins', type: 'categories', val: 5, icon: 'cabin.png',
},
{
    name: 'Camping', type: 'categories', val: 4, icon: 'camping.png',
},
{
    name: 'Domes', type: 'categories', val: 6, icon: 'dome.png',
},
{
    name: 'A Frames', type: 'categories', val: 10, icon: 'aframe.png',
},
{
    name: 'Premium', type: 'categories', val: 7, icon: 'premium.png',
},
{
    name: 'Boat House', type: 'categories', val: 11, icon: 'boathouse.png',
},
{
    name: 'Tree House', type: 'categories', val: 12, icon: 'treehouse.png',
}
]