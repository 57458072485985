'use client';

import { IoMicOutline, IoSearchOutline } from 'react-icons/io5'
import { useRef, useState, useEffect } from 'react'
import { motion, useInView } from 'framer-motion'
import AnimatedTextWord from 'components/utilities/animatedtext'
import Link from 'next/link';
import { suggestions, words } from 'content/homepage';

function ChangingText() {
    const [index, setIndex] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((index) => {
                if (index === suggestions.length - 1) {
                    return 0
                } else {
                    return index + 1
                }
            })
        }, 2000)
        return () => clearInterval(interval)
    }, [])

    return (
        <AnimatedTextWord key={index} text={suggestions[index]} />
    )
}

function ChangingTypes() {
    const [index, setIndex] = useState(0)

    const item = {
        visible: {
            opacity: 1,
            y: 0,
        },
        hidden: {
            opacity: 0,
            y: 20,
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((index) => {
                if (index === words.length - 1) {
                    return 0
                } else {
                    return index + 1
                }
            })
        }, 1500)
        return () => clearInterval(interval)
    }, [])

    return (
        <motion.div initial="hidden" animate="visible">
            <span>Discover the world of&nbsp;</span>
            <motion.span key={index} variants={item} className="text-primary inline-block">{words[index]}</motion.span>
        </motion.div>
    )
}

const SearchBar = () => {
    const [initial, setInitial] = useState(true)
    const ref = useRef(null)
    const isInView = useInView(ref, { once: false })
    const variants = {
        visible: {
            boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
        },
        hidden: {
            boxShadow: "0 3px 10px rgb(0 0 0 / 10%)"
        }
    };

    useEffect(() => {
        if (initial) {
            setInitial(false)
        }
    }, [])

    return <>
        <div ref={ref} className="text-3xl font-semibold leading-10 z-10 relative">
            {initial?<h2>Discover the world of <span className="text-primary">Unique Stays</span>
            </h2> :<ChangingTypes />}
        </div>
        {!isInView && !initial ? <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={`py-3 px-5 shadow-sm fixed top-0 left-0 h-16 w-full bg-white backdrop-filter backdrop-blur-lg bg-opacity-80 z-20`}>
        </motion.div> : null}
        <div className={`${!isInView && !initial ? 'fixed top-0 left-0 z-20 h-16 w-screen flex items-center justify-center' : 'z-10'}`}>
            <Link href="/search" legacyBehavior>
                <motion.div
                    animate={isInView && !initial ? "visible" : "hidden"}
                    variants={variants}
                    className={`rounded-full w-full h-12 bg-white px-4 flex items-center justify-between ${!isInView && !initial ? 'mx-6' : ''} ${initial ? 'shadow' : ''}`}>
                    <div className="flex space-x-4 items-center">
                        <IoSearchOutline className='text-muted text-2xl' />
                        <div className='text-muted truncate'>
                            {isInView || initial ? 'Anywhere' : <ChangingText />}
                        </div>
                    </div>
                    <IoMicOutline className='text-muted text-2xl' />
                </motion.div>
            </Link>
        </div>
    </>
}

export default SearchBar