'use client';

import React, { useContext, useEffect, useRef, useState } from "react";
import Link from 'next/link'
import Image from "lib/image";
import { FaStar, FaTimes } from 'react-icons/fa'
import InfiniteScroll from 'react-infinite-scroll-component';
import Heart from 'components/packs/components/heart';
import { useInView, motion, useScroll, useMotionValueEvent } from "framer-motion";
import { useInfiniteFetch } from 'lib/useInfiniteFetch';
import { TbArrowsSort, TbCircleChevronRight } from "react-icons/tb";
import MobileDialog from "components/utilities/mobiledialog";
import { MdOutlineClose } from "react-icons/md";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { IoChevronForwardCircleOutline, IoCloseOutline } from "react-icons/io5";
import { BiTrendingUp } from "react-icons/bi";
import { formatMealsIncluded } from "lib/utillities/js_funcs";
import { GiHotMeal, GiMeal } from "react-icons/gi";
import { variants } from "lib/utillities/vars";
import { menu } from "content/categories";
import { pluralize } from "lib/utilities";
import { Context } from "components/context";
import Special from "./special";


export function Placeholder() {
    let rows = [];
    for (var i = 0; i < 5; i++) {
        rows.push(<div key={i} className="flex space-x-4 container mb-4 justify-between items-center">
            <div className="h-48 w-40 animate-pulse bg-gray-200 relative rounded-xl shadow-lg overflow-hidden">
            </div>
            <div className="grow animate-pulse">
                <div className="py-3 px-2">
                    <div className="h-4 bg-gray-200 rounded mb-2 w-full">
                    </div>
                    <div className="flex justify-between items-center mt-1">
                        <div className="w-16 h-2 bg-gray-200 rounded"></div>
                        <div className="rounded-full bg-gray-200 h-4 w-4"></div>
                    </div>
                    <div className="h-2 bg-gray-200 rounded mt-2 w-full">
                    </div>
                </div>
            </div>
        </div>)
    }
    return rows
}

export function SinglePack({ pack, apiType }) {
    const min = 0
    return (
        <a
            target="_blank"
            key={pack.uname}
            href={`/${pack.uname}`}>
            <div className="flex space-x-4 container mb-4">
                <div className='relative h-48 w-40 rounded-xl overflow-hidden min-w-[10rem] bg-gray-100'>
                    {pack?.img ? <Image
                        className="object-cover"
                        layout="fill"
                        {...(pack.img?.blurData ? {
                            placeholder: "blur",
                            blurDataURL: pack?.img?.blurData
                        } : {})}
                        src={'uploads/' + pack?.img?.src}
                    /> : null}
                    {pack.pack_type === 3 ? <div className="absolute top-2 left-0 z-10 px-2 py-0.5 bg-gradient-to-r from-pink-600 to-villa-800 opacity-90 rounded-r-full text-white text-xs">
                        Entire Villa
                    </div> : null}
                    <div className="absolute top-1 right-1 z-10">
                        <Heart uname={pack.uname} />
                    </div>
                </div>
                <div className="flex flex-col justify-center min-w-0">
                    <h2 className="text-sm font-medium">{pack.fancyname}</h2>
                    <p className="truncate text-xs text-gray-600 mt-0.25">{pack.hightlight_text}</p>
                    <div className='font-extralight flex items-center justify-between w-full text-xs mt-1 space-x-2'>
                        <p className="truncate">{pack.city?.name || pack.city}, {pack.state}</p>
                        <div className='flex items-center'>
                            <FaStar
                                className="mr-1" />
                            <p>
                                {pack.rating ? (apiType === 'direct' ? JSON.parse(pack.rating).avg_rating : pack.rating) : 'New'}
                            </p>
                        </div>
                    </div>
                    {min || pack.price ? <p className="text-sm flex items-center text-gray-600 mt-3"><span className="line-through text-xs">₹{min ? (min + 300) : (pack.price + 300)}</span>&nbsp;<span className="font-bold">₹{min ? min : pack.price}</span>{pack.minimum ? <span className="text-xs truncate">{pack.pack_type === 3 ? <span className="text-[0.65rem]">&nbsp;| Max {pack.maximum} Guests</span> : <>/Person</>}</span> : null}</p> : null}
                    {pack?.meals?.length ? <div className="bg-hac-100 bg-opacity-80 p-1 rounded text-xs flex items-center space-x-1 mt-3">
                        <Image src="/assets/icons/cloche.png" width={16} height={16} /> <span>{formatMealsIncluded(pack.meals)}</span>
                        {/* <BiTrendingUp className="text-base" /> <span>{pack._count - (pack._count % (pack._count < 25 ? 10 : 25))}+ Bookings Recently</span> */}
                    </div> : null}
                </div>
            </div>

        </a>
    );
}

const icons = [{ icon: 'verified_3d', label: 'Verified Properties' },
{ icon: 'secured_3d', label: 'Secure Payments' }, { icon: 'review_3d', label: 'Authentic Reviews' }]

const HomePacks = ({ params, limit = 10, apiType }) => {
    const context = useContext(Context)

    const {
        data,
        error,
        isLoadingMore,
        size,
        setSize,
        isReachingEnd,
    } = useInfiniteFetch(`/api/external/ai/search`, `lat=${context.state?.currentLocation?.lat}&lng=${context.state?.currentLocation?.lng}&params=${params ? JSON.stringify(params) : ''}`)

    return <div className="pt-2">
        <InfiniteScroll
            dataLength={data.length}
            next={() => setSize(size + 1)}
            hasMore={!isReachingEnd}
            loader={<div className="text-center">
                <Placeholder />
            </div>}
            endMessage={null}
            style={{ overflow: 'hidden' }}
            scrollThreshold={0.4}
        >
            {data?.map((pack, index) => (
                <React.Fragment key={index}>
                    {index === 3? <div className='pb-8 relative'><Special /></div> : null}
                    {index === 7 ? <div className='mt-6 mb-8 py-4 bg-yellow-50'>
                        <h2 className="font-medium container relative z-10">Why Choose HireACamp?</h2>
                        <div className="flex space-x-4 container mt-4 justify-between">
                            {icons.map((icon, index) => (
                                <div key={index} className="flex flex-col items-center justify-center">
                                    <Image src={'assets/icons/' + icon.icon + '.png'} width="100" height="100" />
                                    <p className="text-center mt-1 text-sm">{icon.label}</p>
                                </div>
                            ))}
                        </div>
                    </div> : null}
                    {index === 18 ? <div className='body-p-x pt-6 pb-8 relative'>
                        <Link href="/campzine/we-hired-a-new-look-for-hire-a-camp" legacyBehavior>
                            <Image className="w-full h-auto" src="assets/banners/new-hireacamp.png" layout="responsive" width="358" height="110" />
                        </Link>
                    </div> : null}
                    {index === 12 ? <div className='body-p-x pt-6 pb-8'>
                        <div className="relative h-48 overflow-hidden rounded-lg shadow">
                            <Link href="/campzine">
                                <Image className="object-cover" src="assets/banners/Magazine.jpg" fill />
                            </Link>
                            <div className="absolute bottom-2 right-2 text-white flex items-center space-x-1 text-sm">
                                <span>Read More</span>
                                <TbCircleChevronRight className="text-lg" />
                            </div>
                        </div>
                    </div> : null}
                    <SinglePack key={pack.uname} pack={pack} apiType={apiType} />
                </React.Fragment>)
            )}
        </InfiniteScroll >
    </div>
}

const sortTypes = [{ label: 'Nearest', val: '' }, { label: 'Relevance', val: 'ranking:asc' }, { label: 'Price - Low to High', val: 'price:asc' }, { label: 'Price - High to Low', val: 'price:desc' }, { label: 'Rating', val: 'rating:desc' }, { label: 'Total Ratings', val: 'total_ratings:desc' }]

function VerticalCampsites() {
    const [active, setActive] = useState(0)
    const [stick, setStick] = useState(false)
    const ref = useRef(null)
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["end end", "start start"]
    });

    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        if (latest > 0 && latest < 1) {
            setStick(true)
        }
        else {
            setStick(false)
        }
    })

    const [open, setOpen] = useState(false)
    const [sort, setSort] = useState(0)
    const handleOpen = (e) => {
        e.stopPropagation()
        setOpen(true)
    }

    useEffect(() => {
        if (ref.current.getBoundingClientRect().y < 0) {
            ref.current.scrollIntoView()
        }
    }, [active, sort])

    const params = {
        filter: ['type=package', []]
    }

    if (sortTypes[sort].val) {
        params.sort = [
            sortTypes[sort].val,
            "_count:desc",
        ]
    }

    if (menu[active].val) {
        params.filter[1].push(`${menu[active].type}=${menu[active].val}`)
        if (menu[active].synced) {
            params.filter[1].push(`synced=true`)
        }
    }


    return (
        <div ref={ref} className="-mt-8">
            <div className="pt-16">
                <div className="h-[70px]">
                    {stick ? <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className={`py-3 px-5 shadow-sm fixed border-b top-16 left-0 h-12 w-full bg-white backdrop-filter backdrop-blur-lg bg-opacity-80 z-20`}>
                    </motion.div> : null}
                    <div className={stick ? 'fixed left-0 z-20 h-12 w-screen flex items-center justify-center top-16' : ''}>
                        <div className="flex overflow-x-scroll px-4 hide-scroll-bar">
                            <div className="flex flex-nowrap space-x-12 whitespace-nowrap h-12">
                                {menu.map((item, index) => (
                                    <motion.div whileTap={{ scale: 0.9 }} key={index} animate={index === active ? 'active' : 'inactive'} variants={variants} className="flex flex-col items-center transition duration-200 relative" onClick={() => setActive(index)}>
                                        <Image src={`assets/icons/categorons/${item.icon}`} width={32} height={32} className={`mb-2 w-auto transition duration-200 ${index === active ? 'opacity-100' : 'opacity-60'}`} style={{ height: 20 }} />
                                        <p className={`text-xs ${index === active ? 'font-semibold' : 'font-medium'} capitalize`}>{item.name}</p>
                                        {index === active ? <motion.div initial={{ width: '1.5rem', background: '#f1f1f1' }} animate={{ width: '50%', background: '#000000' }} className="absolute h-0.5 mt-1 border rounded-full border-black border-opacity-20 bottom-0" /> : null}
                                    </motion.div>))}
                            </div>
                        </div>
                    </div>
                </div>
                <HomePacks key={active} params={params} apiType='' />
                {/* <MobileDialog open={open} onClose={() => setOpen(false)}>
                    <div>
                        <div className={`container pt-4 flex items-center md:relative`}>
                            <div onClick={() => setOpen(false)}>
                                <MdOutlineClose className="text-2xl cursor-pointer" />
                            </div>
                        </div>
                        <div className="container pt-6 md:mb-0 md:pt-8" onTouchStart={(e) => e.stopPropagation()}>
                            <h2 className="flex items-center font-semibold md:text-2xl"><TbArrowsSort className="mr-1" /> Sort By</h2>
                            <div className="mt-4">
                                {sortTypes.map((c, key) =>
                                    <div onClick={() => { setSort(key); setOpen(false) }} key={key} className={`cursor-pointer flex items-center justify-between space-x-2 py-6 ${key === (sortTypes.length - 1) ? '' : 'border-b'}`}>
                                        <span className={`text-sm md:text-base ${key === sort ? 'font-semibold text-primary' : ''}`}>{c.label}</span>
                                        {key === sort ? <BsFillCheckCircleFill className="text-primary text-lg" /> : null}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </MobileDialog> */}
            </div>
        </div>
    );
}

export default VerticalCampsites;