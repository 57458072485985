import Image from "lib/image";
import { FaStar } from 'react-icons/fa'
import { useInfiniteFetch } from 'lib/useInfiniteFetch';
import useSWRInfinite from 'swr/infinite'
import InfiniteScroll from 'react-infinite-scroll-component';
import Heart from "./components/heart";
import { HiOutlineChevronRight } from 'react-icons/hi'
import InfiniteScroll1 from 'react-swr-infinite-scroll';
import { useEffect, useRef, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { BiTrendingUp } from "react-icons/bi";
import { formatMealsIncluded } from "lib/utillities/js_funcs";
import { pluralize } from "lib/utilities";

export function Placeholder() {
    let rows = [];
    for (var i = 0; i < 4; i++) {
        rows.push(<div key={i} className="mb-12 w-full animate-pulse">
            <div className="relative bg-gray-200 w-full h-64 rounded-lg shadow-lg">
            </div>
            <div className="mt-4">
                <div className="h-4 bg-gray-200 rounded mb-2 w-3/4">
                </div>
                <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                    <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                </div>
                <div className="flex justify-between items-center mt-1">
                    <div className="w-16 h-2 bg-gray-200 rounded"></div>
                    <div className="rounded-full bg-gray-200 h-6 w-6"></div>
                </div>
            </div>
        </div>)
    }
    return rows
}

export function SinglePack({ pack, city, slider = true, apiType = 'direct' }) {
    const sliderRef = useRef();
    const containerRef = useRef(null);
    const [shouldFetch, setShouldFetch] = useState(1)
    const [isFetching, setIsFetching] = useState(false)
    const [activeIndex, setActiveIndex] = useState(1);
    const [images, setImages] = useState(pack.images)
    const [callbackData, setCallbackData] = useState({ available: [] })

    const handleTouchEnd = (e) => {
        setTimeout(() => {
            setActiveIndex(Math.round(sliderRef.current.scrollLeft / sliderRef.current.offsetWidth + 1));
        }, 200);
    }

    const handlePrevNext = (type, e) => {
        e.preventDefault();
        if (type === 'prev') {
            sliderRef.current.scrollLeft -= sliderRef.current.offsetWidth;
        } else {
            sliderRef.current.scrollLeft += sliderRef.current.offsetWidth;
        }
        handleTouchEnd()
    }

    useEffect(() => {
        if(pack?.pack_type === 4){
            fetch(`/api/camp/${pack.uname}/single_callback`)
            .then(res => res.json())
            .then(data => {
                setCallbackData(data)
            })
        }
        const container = containerRef.current;
        const activeIndicator = container.querySelector(".bg-white");

        if (activeIndicator) {
            const containerRect = container.getBoundingClientRect();
            const activeRect = activeIndicator.getBoundingClientRect();

            if (
                activeRect.left < containerRect.left ||
                activeRect.right > containerRect.right
            ) {
                container.scrollTo({
                    left: activeRect.left - containerRect.left + container.scrollLeft,
                    behavior: "smooth"
                });
            }
        }

        if (activeIndex > 1 && shouldFetch === 1 && !isFetching) {
            fetchImages()
        }
        else if (activeIndex % 8 === 0 && shouldFetch && !isFetching) {
            fetchImages()
        }
    }, [activeIndex]);

    const fetchImages = () => {
        setIsFetching(true)
        fetch(`/api/camp/${pack.uname}/images?prefetched=true&page=${shouldFetch}&limit=10`)
            .then(res => res.json())
            .then(data => {
                setImages([...images, ...data])
                setShouldFetch(data.length < 10 ? false : shouldFetch + 1)
                setIsFetching(false)
            })
            .catch(err => {
                setIsFetching(false)
                console.log(err)
            })
    }

    var min = 0;
    pack?.options?.forEach(o => {
        const prices = o.prices.split(",");
        if (min === 0 || prices[prices.length - 1] < min)
            min = prices[prices.length - 1]
    });

    return <a key={pack.uname} href={"/" + pack.uname} target="_blank">
        <div className={`mb-12 w-full`}>
            <div className="relative h-80 group">
                <div className="relative w-full h-full rounded-lg overflow-hidden shadow-lg flex snap-mandatory snap-x overflow-x-auto hide-scroll-bar scroll-smooth" ref={sliderRef}>
                    {images.map((img, k) => <div onTouchEnd={handleTouchEnd} key={k} className="w-full snap-end snap-always h-80 overflow-hidden relative shrink-0 bg-gray-300">
                        {img.src ? <Image
                            layout="fill"
                            placeholder="blur"
                            blurDataURL={img.blurData}
                            objectFit="cover"
                            src={'uploads/' + img.src}
                        /> : <Image
                            layout="fill"
                            objectFit="cover"
                            src={'uploads/' + img}
                        />}
                    </div>)}
                    {/* {isFetching?<div onTouchEnd={handleTouchEnd} className="w-full snap-end snap-always h-80 overflow-hidden relative shrink-0 bg-gray-300 animate-pulse"></div>:null} Causing image shaking issue */}
                </div>
                <div className="bg-gradient-to-t from-gray-800 absolute h-16 w-full bottom-0 rounded-b-lg"></div>
                {/* {apiType !== 'direct' && pack._count > 9 ? <div className="absolute top-2 left-1 bg-white bg-opacity-80 p-1 rounded-lg text-sm flex items-center space-x-1">
                    <BiTrendingUp className="text-base"/> <span>{pack._count - (pack._count % (pack._count < 25? 10 : 25))}+ Bookings Recently</span>
                </div> : null} */}
                {pack.pack_type === 3 ? <div className="absolute top-2 left-0 px-2 py-1 bg-gradient-to-r from-pink-600 to-villa-800 opacity-80 rounded-r-full text-white text-xs flex items-center space-x-1">
                    <span>Entire Villa</span>
                </div> : null}
                {pack.pack_type === 4 && callbackData?.available?.length? <div className="absolute top-2 left-2 px-3 bg-gray-400 rounded-xl backdrop-filter backdrop-blur-lg bg-opacity-50 text-white text-xs flex items-center">
                    <p className="border-r py-1 pr-2">{new Date(callbackData?.available[0]?.date).toLocaleString('default', { month: 'short', day: 'numeric' })}</p>
                    <p className="py-1 pl-2">{callbackData?.available[0]?.tickets} Slots left</p>
                </div>:null}
                <div className="absolute top-1 right-1">
                    <Heart uname={pack.uname} />
                </div>
                <div className="absolute bottom-4 flex justify-center w-full">
                    <div className="w-16 flex overflow-scroll hide-scroll-bar" ref={containerRef}>
                        {Array(images.length <= 4 && pack.total_images > 5 ? 5 : pack.total_images).fill().map((img, i) => (
                            <button
                                key={i}
                                className={`shrink-0 w-[0.35rem] h-[0.35rem] rounded-full mx-1 focus:outline-none transition-all duration-300 ${activeIndex === i + 1 ? "bg-white" : "bg-gray-400"}`}
                                onClick={() => setActiveIndex(i)}
                            ></button>
                        ))}
                    </div>
                </div>
                <div className="hidden md:block invisible group-hover:visible absolute w-full h-full top-0">
                    <div className="flex justify-between w-full h-full items-center px-2">
                        <button onClick={(e) => handlePrevNext('prev', e)} className={`bg-white bg-opacity-90 rounded-full w-8 h-8 flex items-center justify-center ${activeIndex !== 1 ? '' : 'opacity-0'}`}>
                            <FiChevronLeft />
                        </button>
                        <button onClick={(e) => handlePrevNext('next', e)} className={`bg-white bg-opacity-90 rounded-full w-8 h-8 flex items-center justify-center ${activeIndex !== pack.total_images ? '' : 'opacity-0'}`}>
                            <FiChevronRight />
                        </button>
                    </div>
                </div>

            </div>
            <div className="mt-2">
                <div className="flex justify-between items-center space-x-2">
                    <h2 className="font-semibold">{pack.fancyname}</h2>
                    {pack.pack_type !==4?<div className='text-gray-600 flex items-center text-sm'>
                        <FaStar
                            className="mr-1 text-xs" />
                        <p>
                            {pack.rating ? (apiType === 'direct' ? JSON.parse(pack.rating).avg_rating : pack.rating) : 'New'}
                        </p>
                    </div>:null}
                </div>
                {pack.hightlight_text ? <p className="truncate text-sm text-gray-600 mt-0.25">{pack.hightlight_text}</p> : ''}
                {!pack.hightlight_text && pack.parking ? <p className="truncate text-sm text-gray-600 mt-0.25">{pack.parking}</p> : ''}
                <p className="truncate text-xs text-gray-400 mt-0.5">{pack.areaname ? pack.areaname + ',' : null} {city ? city.name : (pack?.city?.name ? pack.city.name : pack.city)}</p>
                <div className="flex justify-between items-center">
                    <div>
                        {min || pack.price ? <p className="text-sm flex items-center text-gray-600 mt-2"><span className="line-through text-xs">₹{min ? (min + 300) : (pack.price + 300)}</span>&nbsp;<span className="font-bold">₹{min ? min : pack.price}</span><span>{pack.pack_type === 3 ? <>&nbsp;| Max {pack.maximum} Guests</> : <>/Person</>}</span></p> : null}
                        {/* {min || pack.price ? <p className="text-[0.60rem] flex items-center text-gray-600 mt-0.5"><span className="font-medium">₹{min ? (min - 100) : (pack.price - 100)}</span><span>/adult for a group of 10+ People</span></p> : null} */}
                        <div className="flex items-center space-x-1 mt-1">
                            {pack?.meals?.length ? <div className="bg-hac-100 bg-opacity-80 p-1 rounded text-[0.60rem] flex items-center space-x-1">
                                <Image src="/assets/icons/cloche.png" width={12} height={12} /> <span>Free {formatMealsIncluded(pack.meals)}</span>
                            </div> : null}
                            {/* {pack?.tax > 0 ? <p className="text-gray-600 text-[0.60rem]">+ Taxes</p> : null} */}
                        </div>
                    </div>
                    <div className="text-xs border border-gray-200 rounded-full px-1 py-0.5 text-gray-500">
                        <HiOutlineChevronRight className="inline-block" />
                    </div>
                </div>
            </div>
        </div>
    </a>
}

const Packs = ({ filter, sortTypes, sort }) => {
    let params = {
        filter: ['type=package'], "sort": [
            "_count:desc",
        ]
    }
    let nearest = false

    if (sortTypes[sort]?.val) {
        params.sort = [sortTypes[sort].val, ...params.sort]
    }
    else if (sortTypes[sort]?.label === 'Nearest') {
        nearest = true
    }

    params.filter = [...params.filter, ...filter]


    const {
        data,
        error,
        isLoadingMore,
        size,
        setSize,
        isReachingEnd,
    } = useInfiniteFetch(`/api/external/ai/search`, `params=${JSON.stringify(params)}${nearest ? '&nearest=true' : ''}`, { limit: 8 })

    return <div className="mt-4 md:body-p-x">
        <InfiniteScroll
            dataLength={data.length}
            next={() => setSize(size + 1)}
            hasMore={!isReachingEnd}
            loader={<Placeholder />}
            endMessage={null}
            style={{ overflow: 'hidden' }}
            className="md:grid xl:grid-cols-4 md:grid-cols-2 gap-5 mt-2 pb-4 px-4 md:px-0"
            scrollThreshold={0.4}
        >
            {data?.map((pack, k) => <SinglePack apiType="" key={pack.uname} pack={pack} />)}
        </InfiniteScroll>
    </div>
}

export const PacksParams = ({ params }) => {

    const {
        data,
        error,
        isLoadingMore,
        size,
        setSize,
        isReachingEnd,
    } = useInfiniteFetch(`/api/external/ai/search`, `params=${JSON.stringify(params)}`, { limit: 8 })

    return <div className="mt-4 md:body-p-x">
        <InfiniteScroll
            dataLength={data.length}
            next={() => setSize(size + 1)}
            hasMore={!isReachingEnd}
            loader={<Placeholder />}
            endMessage={null}
            style={{ overflow: 'hidden' }}
            className="md:grid xl:grid-cols-4 md:grid-cols-2 gap-5 mt-2 pb-4 px-4 md:px-0"
            scrollThreshold={0.4}
        >
            {data?.map((pack, k) => <SinglePack apiType="" key={pack.uname} pack={pack} />)}
        </InfiniteScroll>
    </div>
}

export default Packs