'use client';

import dynamic from 'next/dynamic';
import LazyLoad from 'react-lazyload';

const Player = dynamic(() =>
    import('@lottiefiles/react-lottie-player').then((mod) => mod.Player),
    { ssr: false }
);

function LazyStars() {
    return <LazyLoad unmountIfInvisible>
        <Player
            autoplay
            loop
            className="absolute top-0 left-0 w-full h-full z-0 opacity-[0.4]"
            src="https://assets4.lottiefiles.com/packages/lf20_7haz8i4t.json"
        >
        </Player>
    </LazyLoad>
}

export default LazyStars