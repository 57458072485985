import dynamic from "next/dynamic";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Slider = dynamic(() => import('react-slick/lib'), {
    ssr: false
})

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "red" }}
            onClick={onClick}
        >
            <FaChevronRight />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "green" }}
            onClick={onClick}
        >
            <FaChevronLeft />
        </div>
    );
}

function MySlider(props) {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        ...props.settings
    };
    return <Slider className="pcslick" {...settings}>
        {props.children}
    </Slider>
}

export default MySlider